(() => {
  const handleHowItWorksPage = () => {
    // If page url has #faq change the tab in the page, and scroll tabs component into the view
    // If page url has #treatments scroll tabs component into the view
    const handlePageHash = () => {
      const urlHash = window.location.hash;

      if (urlHash) {
        const hashName = urlHash.replace('#', '');
        const $tabComponent = document.querySelector('#treatment-faq');
        if (hashName === 'faq' || hashName === 'treatments') {
          if (hashName === 'faq') {
            const $faqTab = $tabComponent?.querySelector(
              '.aaaem-tabs__tablist > li:nth-child(2) > a'
            ) as HTMLAnchorElement;
            $faqTab?.click();
          }

          $tabComponent?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    handlePageHash();
  };

  const init = () => {
    const $howItWorks = document.querySelector('#how-it-works');
    if ($howItWorks) {
      handleHowItWorksPage();
    }
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
